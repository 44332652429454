export default {
  title: "Conversor de medidas culinárias",
  description:
    "Escolha um ingrediente e converta as principais medidas culinárias (xícara de chá, copo americano, colheres de chá, sobremesa e de sopa), para gramas (g), quilogramas (kg), mililitros (ml), litros (l), assim como temperaturas (Celsius, Fahrenheit e Kelvin)",
  formheader: {
    measures: "Medidas",
    temperatures: "Temperaturas",
  },
  form: {
    ingredient: "Ingrediente",
    measure1: "Medida 1",
    measure2: "Medida 2",
    qtd: "Quantidade",
    selectPlaceholder: "Selecione...",
  },
  temperatureform: {
    scale1: "Escala 1",
    scale2: "Escala 2",
    degrees: "Graus",
    selectPlaceholder: "Selecione...",
  },
  keywords: {
    title: "Palavras-chave",
    words:
      "conversor de medidas * medidas culinárias * medidas de ingredientes * porções culinárias * porções de ingredientes * colher de chá para gramas * colher de sopa para gramas * copo americano para gramas * xícara de chá para gramas * colher de chá para copo americano * colher de sopa para copo americano * colher de chá para xícara de chá * colher de sopa para xícara de chá * copo americano para ml * xícara de chá para ml * copo americano para litro * xícara de chá para litro * onças (oz) para gramas * pounds para quilogramas * libras (lb) para quilogramas * Celsius para Fahrenheit * Celsius para Kelvin * Fahrenheit para Celsius * Fahrenheit para Kelvin",
  },
  content: {
    h2: "Tabelas de conversão",
    table1: {
      decimal: "Decimal",
      fraction: "Fracionado",
    },
    contentTable: {
      th1: "Medida",
      th2: "Quantidade",
    },
    p1:
      "Os valores decimais, que podem aparecer como resultados de uma conversão, podem ser interpretados com base no seguinte esquema",
    p2:
      "Os valores utilizados como referência, para cada ingrediente, estão representados nas tabelas abaixo",
    p3:
      "Fonte: essas informações foram coletadas por meio de pesquisa realizada em sites e blogs especializados em culinária. O material acessado apresentava pequenas variações nas quantidades informadas e alguns ajustes foram realizados para minimizá-las",
  },
  copyText: "Desenvolvido por",
  page_not_found: {
    title: "Página não encontrada",
    button: "Voltar para a página inicial",
  },
}
