import React, { useState, useEffect } from "react"
import { FaSpinner } from "react-icons/fa"
import { Link } from "gatsby"

import Layout from "../components/layout"
import ToolBox from "../components/toolbox"
import SEO from "../components/seo"

import ptTranslation from "../translations/pt"
import esTranslation from "../translations/es"
import enTranslation from "../translations/en"

const NotFoundPage = () => {
  const [loading, setLoading] = useState(true)
  const [translation, setTranslation] = useState({})
  useEffect(() => {
    const lang = navigator.language
    const langGeneric = lang.split("-")[0]
    if (langGeneric === "pt") {
      setTranslation(ptTranslation)
      setLoading(false)
    } else if (langGeneric === "es") {
      setTranslation(esTranslation)
      setLoading(false)
    } else {
      setTranslation(enTranslation)
      setLoading(false)
    }
  }, [])
  if (loading) {
    return (
      <div className="loading">
        <FaSpinner />
      </div>
    )
  }
  return (
    <Layout title={translation.title}>
      <SEO title="404: Not found" />
      <ToolBox>
        <div className="page-not-found">
          <h2>{translation.page_not_found.title}</h2>
          <Link to="/">{translation.page_not_found.button}.</Link>
        </div>
      </ToolBox>
    </Layout>
  )
}
export default NotFoundPage
